.App {
  text-align: center;
}

body {
  background: #fff;
  font-family: 'Avenir Light', sans-serif;
}

.landing-page-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.landing-page-container h1 {
  margin: 20px auto;
  text-align: center;
  color: #0d0947;
}

.navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
}

.navbar a {
  text-decoration: none;
  color: #fff;
}

.navbar li a:hover {
  background-color: #fff;
  color: black;
  border-radius: 30px;
}

.Logo {
  height: auto;
  width: auto;
  max-height: 45px;
  max-width: 350px;
}

.dropdown {
  text-align: center;
  background-color: rgb(103, 200, 252);
  box-shadow: 0 1px 1px 0 #333232;
}

.dropdown__button {
  background-color: rgb(103, 200, 252);
  border: rgb(103, 200, 252);
  color: #000;
  cursor: pointer;
  font-size: 16px;
}

.dropdown__menu {
  background-color: rgb(103, 200, 252);
  list-style-type: none;
  margin: 0;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1;
}

.dropdown__option {
  cursor: pointer;
  font-size: 16px;
}

.dropdown__option:hover {
  background-color: #fff;
}

.about-us-section {
  background-image: url(/static/media/Home.eaa7e4f4.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  min-height: 80vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

.about-us-message h2 {
  font-size: 35px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}


.about-us-message h3 {
  font-size: 30px;
  margin-bottom: 5px;
  text-align: center;
}

.about-us-content p {
  font-size: 18px;
  max-width: 500px;
  line-height: 1.5;
  margin-bottom: 30px;
}

.services-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 50px 0;
}

.services-section h2 {
  font-size: 36px;
  margin-bottom: 30px;
}

.services-section ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.services-section ul li {
  font-size: 18px;
  margin-bottom: 20px;
  padding-left: 20px;
  position: relative;
}

.services-section ul li:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 8px;
  width: 10px;
  height: 10px;
  background-color: rgb(103, 200, 252);
  border-radius: 50%;
}

.services-image img {
  max-width: 350px;
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.location-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  margin: 20px 0;
}

.location-section h2 {
  font-size: 36px;
  margin-bottom: 30px;
}

.location-section ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.location-section ul li {
  font-size: 18px;
  margin-bottom: 20px;
  padding-left: 20px;
  position: relative;
}

.location-section ul li:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 8px;
  width: 10px;
  height: 10px;
  background-color: rgb(103, 200, 252);
  border-radius: 50%;
}

.contact-us-section {
  margin: 50px 0;
  background-color: #0d0947;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

.contact-us-container {
  max-width: 600px;
  margin: 0 auto;
}

.contact-us-container h2 {
  font-size: 36px;
  margin-bottom: 30px;
  text-align: center;
}

.contact-form {
  margin-top: 20px;
  padding: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

label {
  font-size: 18px;
  margin-bottom: 10px;
}

input,
textarea {
  padding: 10px;
  font-size: 16px;
  resize: none;
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

input:focus,
textarea:focus {
  outline: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

button[type="submit"] {
  padding: 15px 30px;
  font-size: 20px;
  background-color: rgb(103, 200, 252);
  color: #fff;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

button[type="submit"]:hover {
  background-color: #fff;
  color: rgb(103, 200, 252);
}

.gallery-section {
  margin: 20px 0;
  padding: 20px;
  background-color: #0d0947;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

.gallery-section h2 {
  font-size: 36px;
  margin-top: 5px;
  margin-bottom: 20px;
  color: #fff;
}

.gallery-section h3 {
  font-size: 20px;
  margin-top: 5px;
  margin-bottom: 20px;
  color: #fff;
}

.gallery-images {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

.gallery-images img {
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 768px) {

  /* Typical mobile device width */
  .gallery-images img {
    width: 100%;
    height: 50vh;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }

}

footer {
  background-color: rgb(103, 200, 252);
  color: white;
  padding: 10px;
  margin-top: 6vh;
  text-align: center;
}
.swiper-button-next:hover,
.swiper-button-prev:hover {
  color: white; 
}

:root {
    --swiper-theme-color: rgb(103, 200, 252);
  }
